import type * as Onboarding from '@temperworks/types/src/types/interfaces/Api/V4/Worker/Onboarding'
import { http, HttpResponse, delay } from 'msw'

export const updateSsn = [
  http.put(/^http:\/\/uk\.localhost\/api\/v4\/worker\/profile(:update(-ssn)?)?$/, async ({ request}) => {
    const { ssn } = await request.json()
    const existingNino = "AA123456A"
    await delay(1000)

    const ninoFormatRegex = new RegExp(/^(?!BG)(?!GB)(?!NK)(?!KN)(?!TN)(?!NT)(?!ZZ)(?:[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z])(?:\s*\d\s*){6}([A-D]|\s)$/)

    if(ssn === existingNino || !ninoFormatRegex.test(ssn)) {
      return HttpResponse.json(ssn, { status: 401 })
    } else {
      return HttpResponse.json(ssn, { status: 200 })
    }
  })
]

