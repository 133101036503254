import { setupWorker } from 'msw/browser'
import { updateSsn } from './handlers/signup-onboarding/freeflexer/profile'
import { onboarding } from './handlers/signup-onboarding/freeflexer/onboarding'

const handlers = [
  ...updateSsn,
  ...onboarding
]

const worker = setupWorker(...handlers)

async function startWorker() {
  await worker.start({
    onUnhandledRequest: 'bypass'
  })
}

export { startWorker }